import React, { useEffect, useState } from "react";
import "../assets/scss/explore/explore.css";
import imgexplore from "../assets/images/explore/img-explore.svg";
import bg from "../assets/images/header/bg-bawah.png";
// import particlebottom from "../assets/images/header/particle-bottom.svg";
// 
import { apiUrl, dataUrl } from "../actions/index";

export default function Explore() {
  const [explore, setExplore] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [lengthList, setLengthList] = useState(0);
  const fetchData = () => {
    fetch(`${apiUrl.home()}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setExplore(data.content_slide);
        setLengthList(data.content_slide.length);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const showSlides = (index) => {
    const slides = lengthList;
    setTimeout(() => {
      index++;
      if (index >= slides) {
        index = 0;
      }
      setSlideIndex(index);
    }, 4200);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex, lengthList]);

  return (
    <section className="explore">
      <img src={imgexplore} className="img-explore"></img>
      <img src={bg} className="bg-img"></img>
      {/* <img src={particlebottom} className="particle-bottom"></img> */}
      <div className="container">
        <div className="main-title">
          LET’S <br></br>
          EXPLORE <br></br>
          DEEPER
          <span>ENJOY THE EXPERIENCE INSIDE</span>
        </div>
        <div id="section-explore"></div>
        <div className="content-explore">
          {explore.map((item, index) => (
            <div
              className={`carousel-slide fade-in-right ${
                slideIndex === index ? "d-block" : "d-none"
              }`}
              key={`item-${index}`}
              data-toggle="carousel">
              <div className="title">{item.header}</div>
              <div className="sub-title quotes">
                <div>{item.body}</div>
              </div>
              <div className="title">{item.footer}</div>
            </div>
          ))}

          {/* <div
            className={`carousel-slide fade-in-right ${
              slideIndex === 0 ? "d-block" : "d-none"
            }`}
            
            data-toggle="carousel">
            <div className="title">asdasd</div>
            <div className="sub-title quotes">
              <div>asdasd</div>
            </div>
            <div className="title">asdasd</div>
          </div>
          <div
            className={`carousel-slide fade-in-right ${
              slideIndex === 1 ? "d-block" : "d-none"
            }`}
          
            data-toggle="carousel">
            <div className="title">asdasd</div>
            <div className="sub-title quotes">
              <div>asdasd</div>
            </div>
            <div className="title">asdasd</div>
          </div> */}

          {/* <div className="text-explore">
            <div className="title">
              MENGHADIRKAN <br></br> EXPERIENCE
            </div>
            <div className="sub-title quotes">
              <div>
                {" "}
                Mengemas pesan dengan mengkombinasikan tujuan client, main
                benefit brand project, dan apa yang sebenarnya dibutuhkan oleh
                target market ADALAH kekuatan utama dalam penyelenggaraan event.
              </div>
            </div>
            <div className="title">
              TIDAK KURANG <br></br> TIDAK LEBIH
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
