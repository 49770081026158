import React, { useState, useEffect } from "react";
import { apiUrl } from "../actions";

const withRouter = (WrappedComponent) => (props) => {
  const [listData, setData] = useState([]);
  const fetchData = () => {
    fetch(apiUrl.sosmed())
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 2000);
  }, []);

  return <WrappedComponent data={listData} />;
};

export default withRouter;
