import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import logo from "../assets/images/logo.png";
import imgbottom from "../assets/images/post/img-bottom.svg";
import imgtop from "../assets/images/post/img-top.svg";

import "../assets/scss/home/home.css";
import "../assets/scss/portofolio/portofolio.css";
import "../assets/scss/service/service.css";
import withRouter from "../helpers/API_Service";
import Nav from "../parts/Nav";
import { dataUrl } from "../actions";

class Service extends Component {
  constructor() {
    super()
    this.state = {
      isActive: '',
      service_detail: {
        id: '',
        nama: '',
        isi: '',
      },
      list: [],
      firstLoad: true,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    window.scrollTo(0, 0);  
    if (this.props.data && this.props.data.service_detail && prevState.service_detail.id != this.props.data.service_detail.id && this.state.firstLoad) {
      this.setState({
        isActive: this.props.data && this.props.data.service_detail ? this.props.data.service_detail.id : '',
        list: this.props.data && this.props.data.list.length > 0 ? this.props.data.list : [],
        service_detail: {
          id: this.props.data && this.props.data.service_detail ? this.props.data.service_detail.id : '',
          nama: this.props.data && this.props.data.service_detail ? this.props.data.service_detail.nama : '',
          isi: this.props.data && this.props.data.service_detail ? this.props.data.service_detail.isi : ''
        },
      firstLoad: false,
      })
    }
  }
  
  render() {
    const  setService = (value) =>{
      if(this.state.service_detail.id != value.id) {
        window.history.replaceState(null, null, value.id)
        this.setState({
          isActive: value.id,
          service_detail: {
            id: value.id,
            nama: value.nama,
            isi: value.isi,
          },
          firstLoad: false,
        })
      }
    }
    const showData = () => {
      return (
        <>
          <div className="title">
            {this.state.service_detail.nama
              ? this.state.service_detail.nama
              : ""}
          </div>

          <div
            className="desc"
            dangerouslySetInnerHTML={{
              __html: this.state.service_detail.isi
                ? this.state.service_detail.isi
                : "",
            }}></div>
        </>
      );
    };
    const showSkeleton = () => {
      return (
        <>
          <SkeletonTheme color="grey" highlightColor="#bfbfbf">
            <div className="title">
              {" "}
              <Skeleton height={20} width={300} />
            </div>

            <div className="desc">
              {" "}
              <Skeleton height={160} />
            </div>
          </SkeletonTheme>
        </>
      );
    };

    const showSkeletonService = () => {
      var arr = new Array(5).fill('');
      return (<>
        <SkeletonTheme color="grey" highlightColor="#bfbfbf">
          <div className="side-menu">
            {arr.map((value, key) => (
              <div key={key} className="menu-item d-flex align-items-center">
                <Skeleton height={50} width={50} />
                <div className="title-menu w-100">
                  <Skeleton height={30} />
                </div>
              </div>
            ))}
          </div>
        </SkeletonTheme>
      </>)
    }

    const showDataService = () => {
      return (<div className="side-menu">
        {this.state.list.map((value, index) => (
          <>
            <div className={`menu-item ${value.id === this.state.isActive ? 'active' : ''}` } key={index} onClick={e => setService(value)}>
              <div className="d-flex align-items-center mx-2">
                <img src={dataUrl.service()+'/'+value.icon}></img>
                <div className="title-menu">
                  {value.nama}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>);
    }

    return (
      <>
        <div className="post-page service">
          <Nav></Nav>
          <img src={imgbottom} className="img-bottom"></img>
          <img src={imgtop} className="img-top"></img>
          <img src={logo} className="logo-footer"></img>
          {this.state.tesData}
          <div className="main-content">
            <div className="container">
              <div className="content">
                <div className="title-section">The Service</div>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    {this.props.loading ?
                      showSkeletonService()
                      : showDataService()}

                  </div>
                  <div className="col-md-8 mt-5">
                    {this.props.loading ? showSkeleton() : showData()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div>COPYRIGHT PT. JAWARA KREASITAMA </div>
            <div>
              ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE{" "}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Service);
