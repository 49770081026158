import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/download/download.css";
import imgPdf from "../assets/images/download/img-pdf.png";
import imgPng from "../assets/images/download/img-png.png";
import imgZip from "../assets/images/download/img-zip.png";
import imgPpt from "../assets/images/download/img-ppt.png";
import imgDoc from "../assets/images/download/img-doc.png";
import iconDownload from "../assets/images/download/icon-download.svg";
import iconDrive from "../assets/images/download/icon-drive.svg";
import { apiUrl, dataUrl } from "../actions/index";

export default function Download({data}) {
  const [download, setDownload] = useState([]);
  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setDownload(data.download);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    setDownload(data);
  }, [data]);

  const getExtention = (jenisFile, file) => {
    if (jenisFile == "drive") {
      return `${dataUrl.download()}icon/drive.png`;
    } else {
      var split = file.split(".");
      var extension = split[split.length - 1];
      if (extension == "png") {
        return `${dataUrl.download()}icon/img-png.png`;
      } else if (extension == "jpg") {
        return `${dataUrl.download()}icon/img-png.png`;
      } else if (extension == "ppt") {
        return `${dataUrl.download()}icon/img-ppt.png`;
      } else if (extension == "zip") {
        return `${dataUrl.download()}icon/img-zip.png`;
      } else if (extension == "pdf") {
        return `${dataUrl.download()}icon/img-pdf.png`;
      } else if (extension == "doc") {
        return `${dataUrl.download()}icon/img-doc.png`;
      } else if (jenisFile == "file"){
        return `${dataUrl.download()}icon/other_file.png`;
      }
      
    }
  };
  return (
    <section className="download" id="section-download">
      <div className="container">
        <div className="title text-center">Download</div>
        <div className="list-item">
          <div className="row justify-content-center">
            <div className="col-10">
              {download.map((item, index) => (
                <a
                  href={`${
                    item.jenis_file == "drive" ? "" : dataUrl.download()
                  }${item.file}`}
                  target="_blank"
                  download
                  key={`item-${index}`}>
                  <div className="item">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img  src={getExtention(item.jenis_file, item.file)} className="img-file"></img>
                        <div className="title-item">{item.judul}</div>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            item.jenis_file == "drive"
                              ? iconDrive
                              : iconDownload
                          }
                          className="icon-download"></img>
                      </div>
                    </div>
                  </div>
                </a>
              ))}

              {/* <div className="item">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={imgPng} className="img-file"></img>
                    <div className="title-item">
                      Form Registration Crew Sembilan
                    </div>
                  </div>
                  <div className="icon">
                    <img src={iconDownload} className="icon-download"></img>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={imgDoc} className="img-file"></img>
                    <div className="title-item">
                      Form Registration Crew Sembilan
                    </div>
                  </div>
                  <div className="icon">
                    <img src={iconDrive} className="icon-download"></img>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={imgZip} className="img-file"></img>
                    <div className="title-item">
                      Form Registration Crew Sembilan
                    </div>
                  </div>
                  <div className="icon">
                    <img src={iconDrive} className="icon-download"></img>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={imgPpt} className="img-file"></img>
                    <div className="title-item">
                      Form Registration Crew Sembilan
                    </div>
                  </div>
                  <div className="icon">
                    <img src={iconDrive} className="icon-download"></img>
                  </div>
                </div>
              </div> */}
            </div>
            {/* {blog.slice(0, 6).map((item, index) => (
                  <div className="col-md-4 col-12 mb-4">
                    <Link
                      className="item"
                      to={`/blog/${item.id}`}
                      key={`item-${index}`}>
                      <img src={bgDefault}></img>
                      <div className="kategori-item">Opinion</div>
                      <div className="title-item">{item.judul}</div>
                    </Link>
                  </div>
                ))} */}
          </div>
        </div>
        <Link to="/download">
          <div className="text-center mt-5">
            <div className="btn btn-more">See More</div>
          </div>
        </Link>
      </div>
    </section>
  );
}
