import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import logo from "../assets/images/logo.png";
import imgbottom from "../assets/images/post/img-bottom.svg";
import imgtop from "../assets/images/post/img-top.svg";
import "../assets/scss/home/home.css";
import "../assets/scss/portofolio/portofolio.css";
import withRouter from "../helpers/API_Portofolio";
import Lightbox from "../parts/Lightbox";
import Nav from "../parts/Nav";

class Portofolio extends Component {
  formatDate(newdate) {
    const date = new Date(newdate);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }

  render() {
    const listGambar = this.props.data.portofolio_gambar;
    const showData = () => {
      return (
        <>
          <div className="date">
            {`${this.props.data.portofolio_detail.agency} - ${this.props.data.portofolio_detail.brand}  / `}
            {this.formatDate(this.props.data.portofolio_detail.tanggal)} -{" "}
            {this.formatDate(this.props.data.portofolio_detail.tanggal_selesai)}
          </div>
          <div className="title">{this.props.data.portofolio_detail.judul}</div>
          <div
            className="desc"
            dangerouslySetInnerHTML={{
              __html: this.props.data.portofolio_detail.summary,
            }}
          ></div>
          <Lightbox></Lightbox>
          <div
            className="desc"
            dangerouslySetInnerHTML={{
              __html: this.props.data.portofolio_detail.keterangan,
            }}
          ></div>
        </>
      );
    };
    const showSkeleton = () => {
      return (
        <>
          <SkeletonTheme color="grey" highlightColor="#bfbfbf">
            <div className="title">
              {" "}
              <Skeleton height={20} width={300} />
            </div>

            <div className="desc">
              {" "}
              <Skeleton height={160} />
            </div>
          </SkeletonTheme>
        </>
      );
    };

    return (
      <>
        <div className="post-page">
          <Nav></Nav>
          <img src={imgbottom} className="img-bottom"></img>
          <img src={imgtop} className="img-top"></img>
          <img src={logo} className="logo-footer"></img>
          <div className="main-content">
            <div className="container">
              <div className="content">
                {this.props.data.length == 0 ? showSkeleton() : showData()}
              </div>
            </div>
            
            {/* <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/5UihqGaL5nM"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                /> */}
            <div className="container">
              <div className="content">
                <div className="desc desc-long"></div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
            <div>
              ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE{" "}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Portofolio);
