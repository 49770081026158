import React, { Component, useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import logo from "../../assets/images/logo.png";
import imgbottom from "../../assets/images/post/img-bottom.svg";
import imgtop from "../../assets/images/post/img-top.svg";
import "../../assets/scss/home/home.css";
import "../../assets/scss/portofolio/portofolio.css";
import Nav from "../../parts/Nav";
import iconDownload from "../../assets/images/download/icon-download.svg";
import iconDrive from "../../assets/images/download/icon-drive.svg";
import { apiUrl, dataUrl } from "../../actions/index";
import ReactPaginate from "react-paginate";

export default function MoreDownload() {
  const [download, setDownload] = useState([]);
  const [listPage, setPage] = useState([]);
  const [filter, setFilter] = useState(
    {
      currentPage: 1,
    }
  );
  const fetchData = () => {
    fetch(`${apiUrl.download()}&page=1`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDownload(data.list.data);
        setPage(data.list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
 
  useEffect(() => {
    fetchData();
  }, []);

  const filterData = async (props) => {
    fetch(`${apiUrl.download()}&page=${props.currentPage}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDownload(data.list.data);
        setPage(data.list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    filter.currentPage = currentPage;
    setFilter(filter);
    filterData(filter);
   
  };
  // console.log(setNumberPage);
  const getExtention = (jenisFile, file) => {
    if (jenisFile == "drive") {
      return `${dataUrl.download()}icon/drive.png`;
    } else {
      var split = file.split(".");
      var extension = split[split.length - 1];
      if (extension == "png") {
        return `${dataUrl.download()}icon/img-png.png`;
      } else if (extension == "jpg") {
        return `${dataUrl.download()}icon/img-png.png`;
      } else if (extension == "ppt") {
        return `${dataUrl.download()}icon/img-ppt.png`;
      } else if (extension == "zip") {
        return `${dataUrl.download()}icon/img-zip.png`;
      } else if (extension == "pdf") {
        return `${dataUrl.download()}icon/img-pdf.png`;
      } else if (extension == "doc") {
        return `${dataUrl.download()}icon/img-doc.png`;
      } else if (jenisFile == "file"){
        return `${dataUrl.download()}icon/other_file.png`;
      }
      
    }
  };

  // const listGambar = this.props.data.portofolio_gambar;
  // const showData = () => {
  //   return (
  //     <>
  //       <div className="date">
  //         {`${this.props.data.portofolio_detail.agency} - ${this.props.data.portofolio_detail.brand}  / `}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal)} -{" "}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal_selesai)}
  //       </div>
  //       <div className="title">{this.props.data.portofolio_detail.judul}</div>

  //       <div
  //         className="desc"
  //         dangerouslySetInnerHTML={{
  //           __html: this.props.data.portofolio_detail.keterangan,
  //         }}></div>
  //     </>
  //   );
  // };
  // const showSkeleton = () => {
  //   return (
  //     <>
  //       <SkeletonTheme color="grey" highlightColor="#bfbfbf">
  //         <div className="title">
  //           {" "}
  //           <Skeleton height={20} width={300} />
  //         </div>

  //         <div className="desc">
  //           {" "}
  //           <Skeleton height={160} />
  //         </div>
  //       </SkeletonTheme>
  //     </>
  //   );
  // };

  return (
    <>
      <div className=" post-page download">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              <div className="title text-center">Download</div>
              <div className="list-item">
                <div className="row justify-content-center">
                  <div className="col-10">
                    {download.map((item, index) => (
                      <a
                        href={`${
                          item.jenis_file == "drive" ? "" : dataUrl.download()
                        }${item.file}`}
                        target="_blank"
                        download
                        key={`item-${index}`}>
                        <div className="item">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                src={getExtention(item.jenis_file, item.file)}
                                className="img-file"></img>
                              <div className="title-item">{item.judul}</div>
                            </div>
                            <div className="icon">
                              <img
                                src={
                                  item.jenis_file == "drive"
                                    ? iconDrive
                                    : iconDownload
                                }
                                className="icon-download"></img>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                    {/* <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={listPage.last_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}
