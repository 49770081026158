import React, { useState, useEffect }  from "react";
import { useParams } from "react-router-dom";
import "../assets/scss/home/home.css";
import "../assets/scss/portofolio/portofolio.css";
import Nav from "../parts/Nav";
import imgtop from "../assets/images/post/img-top.svg";
import imgbottom from "../assets/images/post/img-bottom.svg";
import imgporto1 from "../assets/images/post/img-porto-1.jpg";
import imgporto2 from "../assets/images/post/img-porto-2.jpg";
import logo from "../assets/images/logo.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
export default function DetailPost() {
  
    const url = process.env.REACT_APP_DATA_URL+"/post";

    const params = useParams();
    const [listData, setData] = useState([]);
    const [onGetData, setLoading] = useState(true);
    const fetchData = () => {
      fetch(process.env.REACT_APP_API_URL+`/detail_post/${params.id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setLoading(false)
          setData(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    useEffect(() => {
      setTimeout(() => {
        fetchData();
      }, 2000);
    }, []);

    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 1,
    });
  
    function openLightboxOnSlide(number) {
      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number,
      });
    }
    
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        centerMode: true,
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        centerMode: true,
        breakpoint: { max: 1024, min: 767 },
        items: 2,
      },
      mobile: {
        centerMode: false,
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    const showData = () => {
      return (
        <>
          <div className="title">{listData.post_detail.judul}</div>
          <div className="desc" dangerouslySetInnerHTML={{ __html: listData.post_detail.isi }}>
          </div>
          {listData.post_gambar.length === 0 ? (
          ""
        ) : listData.post_gambar.length === 1 ? 
        (
          <div>
            {listData.post_gambar.map((item, index) => (
              <div className="card">
                <img
                  key={`item-${index}`}
                  className="product--image"
                  onClick={() => openLightboxOnSlide(index + 1)}
                  src={`${url}/${item.file}`}
                />
              </div>
            ))}
          </div>
        )
        : 
        (
          <Carousel
            centerMode
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            className=""
            dotListClass=""
            draggable
            focusOnSelect={true}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {listData.post_gambar.map((item, index) => (
              <div className="card">
                <img
                  key={`item-${index}`}
                  className="product--image"
                  onClick={() => openLightboxOnSlide(index + 1)}
                  src={`${url}/${item.file}`}
                />
              </div>
            ))}
          </Carousel>
        )} 
        </>
      );
    };
    const showSkeleton = () => {
      return (
        <>
          <SkeletonTheme color="grey" highlightColor="#bfbfbf">
            <div className="title">
              {" "}
              <Skeleton height={20} width={300} />
            </div>

            <div className="desc">
              {" "}
              <Skeleton height={160} />
            </div>
          </SkeletonTheme>
        </>
      );
    };
    return (
      <>
        <div className="post-page">
          <Nav></Nav>
          <img src={imgbottom} className="img-bottom"></img>
          <img src={imgtop} className="img-top"></img>
          <img src={logo} className="logo-footer"></img>
          <div className="main-content">
            <div className="container">
              <div className="content">
              {onGetData ? showSkeleton() : showData()}
              {/* <div className="title">{listData.post_detail.judul}</div> */}

              {/* <div className="desc" dangerouslySetInnerHTML={{ __html: listData.post_detail.isi }}>
              </div> */}
              </div>
            </div>
          </div>
          <div className="copyright">
            <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
            <div>
              ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE{" "}
            </div>
          </div>
        </div>
      </>
    );
}

