import React, { useEffect, useState } from "react";
import "../assets/scss/service/service.css";
import particle from "../assets/images/service/particle.svg";
import background from "../assets/images/service/background.png";
import { NavLink } from "react-router-dom";
import { apiUrl, dataUrl } from "../actions/index";

export default function Service({data}) {
  const [service, setService] = useState([]);

  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setService(data.service);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    // fetchData();
    setService(data);
  }, [data]);
  return (
    <section className="service" id="section-service">
      <img src={particle} className="particle"></img>
      <img src={background} className="bg-img"></img>
      <div className="container content">
        <div className="wrapper-service">
          <div className="title">THE SERVICES</div>
          <div className="row justify-content-center align-items-center mt-5">
            {/* <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item1}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item2}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item3}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item4}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item5}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item6}></img>
              </NavLink>
            </div> */}
            {service.map((item, index) => (
              <div className="col-lg-4 col-sm-12 mb-5" key={`item-${index}`}>
                <NavLink href="" to={`/service/${item.id}`} className=" item">
                  <img src={`${dataUrl.service()}${item.thumbnail}`}></img>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
