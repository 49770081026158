import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import facebook from "../assets/images/sosmed/facebook.svg";
import instagram from "../assets/images/sosmed/instagram.svg";
import mail from "../assets/images/sosmed/mail.svg";
import twitter from "../assets/images/sosmed/twitter.svg";
import "../assets/scss/nav/nav.css";
import $ from "jquery";
import withRouter from "../helpers/API_Sosmed";

class Nav extends Component {
  urlHttps(https) {
    if (https) {
      let hasil = https.indexOf("https", 0);

      if (hasil < 0) {
        hasil = "https://" + https;
      } else {
        hasil = https;
      }
      return hasil;
    }
  }
  componentDidMount() {
    let navbar = $(".nav");
    $(window).scroll(function() {
      let windowPosition = window.scrollY > 0;
      navbar.toggleClass("scrolling-active", windowPosition);
    });
    const { hash } = window.location;
    const scroll = "";
    $(this).scrollTop(0);
    // console.log($("#section-service").offset().top);
    if (hash) {
      if (hash == "#section-contact")
        $("html, body").animate({
          scrollTop: $(hash).position().top + 400,
        });
      if (hash == "#section-post")
        $("html, body").animate({
          scrollTop: $(hash).position().top + 200,
        });
      if (hash == "#section-family")
        $("html, body").animate({
          scrollTop: $(hash).position().top + 400,
        });
      if (hash == "#section-experience")
        $("html, body").animate({
          scrollTop: $(hash).position().top + 700,
        });
      else {
        $("html, body").animate({
          scrollTop: $(hash).position().top - 30,
        });
      }
    }

    $(".nav .nav-item").click(function() {
      $("body").animate({
        scrollTop: $($(this).attr("href")).offset().top - 30,
      });
      $(".nav").removeClass("nav-collapse");
    });
    $(".toggle").click(function() {
      navbar.addClass("nav-collapse");
    });

    $(".button-close").click(function() {
      navbar.removeClass("nav-collapse");
    });
  }

  render() {
    const { pathname } = window.location;

    return (
      <div className="header">
        <div className="container">
          <div className="nav">
            <div className=" nav-wrapper d-flex align-items-lg-center">
              <div className="position-relative">
                <Link to={"/home"} className="wrapper-logo">
                  <img src={logo} className="logo"></img>
                </Link>
                <div className="button-close">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="#26B2C3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="#26B2C3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <ul className="list-nav">
                <li>
                  <a href="/home" className="nav-item">
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname == "/home"
                        ? "#section-explore"
                        : "/home?#section-explore"
                    }
                    className="nav-item"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname == "/home"
                        ? "#section-service"
                        : "/home?#section-service"
                    }
                    className="nav-item"
                  >
                    The Industry
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname == "/home"
                        ? "#section-experience"
                        : "/home?#section-experience"
                    }
                    className="nav-item"
                  >
                    The Experience
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname == "/home"
                        ? "#section-family"
                        : "/home?#section-family"
                    }
                    className="nav-item"
                  >
                    The Family
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname == "/home"
                        ? "#section-contact"
                        : "/home?#section-contact"
                    }
                    className="nav-item"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname == "/home"
                        ? "#section-post"
                        : "/home?#section-post"
                    }
                    className="nav-item"
                  >
                    Blog
                  </a>
                </li>
              </ul>

              <ul className="sosmed">
                <li>
                  <a
                    href={this.urlHttps(this.props.data.instagram)}
                    target="blank"
                  >
                    {" "}
                    <img src={instagram} className=""></img>
                  </a>
                </li>
                <li>
                  <a href={this.urlHttps(this.props.data.email)} target="blank">
                    {" "}
                    <img src={mail} className=""></img>
                  </a>
                </li>
                <li>
                  <a
                    href={this.urlHttps(this.props.data.twitter)}
                    target="blank"
                  >
                    {" "}
                    <img src={twitter} className=""></img>
                  </a>
                </li>
                <li>
                  <a
                    href={this.urlHttps(this.props.data.facebook)}
                    target="blank"
                  >
                    {" "}
                    <img src={facebook} className=""></img>
                  </a>
                </li>
              </ul>
              <div className="toggle">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 12H21"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 6H21"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 18H21"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Nav);
