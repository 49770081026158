import React from "react";
import Explore from "../parts/Explore";
import "../assets/scss/hero/hero.css";
import bg from "../assets/images/header/bg-atas.png";
// import kota1 from "../assets/images/header/kota-1.svg";
import imgcentermobile from "../assets/images/header/Header.png";

export default function Hero() {
  return (
    <>
      <div className="wrapper-hero">
        <div className="hero">
          {/* <img src={bg} className="bg"></img> */}

          <img src={imgcentermobile} className="img-center-mobile"></img>

          {/* <img src={kota1} className="kota"></img> */}
        </div>
        {/* <Explore></Explore> */}
      </div>
    </>
  );
}
