import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import logo from "../../assets/images/logo.png";
import imgbottom from "../../assets/images/post/img-bottom.svg";
import imgtop from "../../assets/images/post/img-top.svg";
import "../../assets/scss/home/home.css";
import "../../assets/scss/post/post.css";
import withRouter from "../../helpers/API_Blog";
import { useParams } from "react-router-dom";
import Nav from "../../parts/Nav";
import { apiUrl, dataUrl } from "../../actions/index";
import bgDefault from "../../assets/images/post/bg-default-blog.png";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/id.js";

export default function Blog() {
  const params = useParams();
  const [listData, setData] = useState([]);
  const [onGetData, setLoading] = useState(true);
  const [arsip, setArsip] = useState([]);
  const [kategori, setKategori] = useState([]);
  const [currentYear, setYear] = useState("");
  const fetchData = () => {
    fetch(`${apiUrl.detailBlog(params.id)}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data.blog_detail);
        setLoading(false);
        setKategori(data.kategori);
        setArsip(data.arsip);
        console.log(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 2000);
  }, []);
  const showData = () => {
    return (
      <>
        <div className="title text-center">{listData.judul}</div>
        {/* <div className="quotes text-center mb-3">
          “Jangan pernah menganggap remeh sebuah pekerjaan. Karena di setiap
          pekerjaan ada kepercayaan dari client yang harus benar-benar dijaga,
          lebih dari apapun.”
        </div> */}
        <img
          src={
            `${listData.thumbnail == null}`
              ? bgDefault
              : `${dataUrl.blog()}${listData.thumbnail}`
          }
          className="mb-3 img-blog"
        ></img>
        <div
          className="desc"
          dangerouslySetInnerHTML={{
            __html: listData.isi,
          }}
        ></div>
      </>
    );
  };
  const showSkeleton = () => {
    return (
      <>
        <SkeletonTheme color="grey" highlightColor="#bfbfbf">
          <div className="title">
            {" "}
            <Skeleton height={20} width={300} />
          </div>

          <div className="desc">
            {" "}
            <Skeleton height={160} />
          </div>
        </SkeletonTheme>
      </>
    );
  };
  return (
    <>
      <div className="post-page post">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              <div className="row">
                <div className="col-md-2">
                  <div className="list-filter">
                    <div className="title-filter">Kategori</div>
                    {kategori.map((item, index) => (
                      <div className="">
                        <Link
                          to={`/blog?filter_kategori=${item}`}
                          className="item-filter"
                          data-value={item}
                        >
                          {item}
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div className="list-filter">
                    <div className="title-filter">Arsip</div>
                    {arsip.map((item, index) => (
                      <div className="">
                        <button
                          type="button"
                          onClick={(e) =>
                            setYear(item.year != currentYear ? item.year : "")
                          }
                          className="item-filter"
                        >
                          {item.year}
                        </button>
                        <div
                          className={`month ${
                            item.year == currentYear ? "d-block" : "d-none"
                          }`}
                        >
                          {item.list.map((month, index) => (
                            <Link
                              to={`/blog?year=${item.year}&month=${month.bulan}`}
                              className="item-filter"
                              data-value={item.year}
                              data-month={month.bulan}
                            >
                              {moment(`${item.year}-${month.bulan}-01`).format(
                                "MMMM"
                              )}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-10">
                  {onGetData ? showSkeleton() : showData()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}
