import React, { useEffect, useState } from "react";
import "../assets/scss/contact/contact.css";
import bg from "../assets/images/contact/bg.png";
import checklist from "../assets/images/icon/checklist.svg";
import bgbawah from "../assets/images/contact/background-1.svg";
import bgatas from "../assets/images/contact/background-2.svg";
import facebook from "../assets/images/sosmed/facebook.svg";
import instagram from "../assets/images/sosmed/instagram.svg";
import mail from "../assets/images/sosmed/mail.svg";
import twitter from "../assets/images/sosmed/twitter.svg";
import Swal from "sweetalert2";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import { apiUrl, dataUrl } from "../actions/index";

import parse from "html-react-parser";
export default function Contact( {data, dataSk, rowAddress}) {
  const [nama, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [telp, setTelp] = useState("");
  const [website, setWebsite] = useState("");
  const [message, setMessage] = useState("");
  const MySwal = withReactContent(Swal);
  const [contact, setContact] = useState([]);
  const [dataAlamat, setAlamat] = useState([]);
  const [sosmed, setSosmed] = useState([]);
  const [sk, setSK] = useState([]);

  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setContact(data.contact);
  //       setAlamat(data.alamat);
  //       setSK(data.sk);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  const fetchSosmed = () => {
    fetch(`${apiUrl.sosmed()}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSosmed(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setContact(data);
      setAlamat(rowAddress);
      setSK(dataSk);
      compareData();
      fetchSosmed();
    }, 2000);
  }, [data, rowAddress, dataSk]);

  const objectRow = (
    index,
    label,
    alamat,
    kecamatan,
    kabupaten,
    provinsi,
    telpon
  ) => {
    return (
      <div className="item-alamat" key={`item-${index}`}>
        <div className="lable">{label}</div>
        <div className="mb-2 alamat">
          {alamat}, {kecamatan}, {kabupaten}, {provinsi} 
          <br />
          Telp. {telpon}
        </div>
      </div>
    );
  };

  const compareData = () => {
    var rows = [];
    var textPrev = "";
    for (const key in dataAlamat) {
      if (dataAlamat.hasOwnProperty(key)) {
        const element = dataAlamat[key];
        if (element.label != textPrev) {
          textPrev = element.label;
          rows.push(
            objectRow(
              key,
              element.label,
              element.alamat,
              element.nama_kecamatan,
              element.nama_kabupaten,
              element.nama_provinsi,
              element.telpon
            )
          );
        } else {
          rows.push(objectRow(key, "", element.alamat, element.nama_kecamatan,
          element.nama_kabupaten,
          element.nama_provinsi,
          element.telpon));
        }
      }
    }
    return rows;
  };

  const urlHttps = (https) => {
    if (https) {
      let hasil = https.indexOf("https", 0);

      if (hasil < 0) {
        hasil = "https://" + https;
      } else {
        hasil = https;
      }
      return hasil;
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    const post = {
      id_web: "a",
      nama: nama,
      alamat: address,
      email: email,
      telp: telp,
      website: website,
      pesan: message,
      waktu_upload: "",
      status: "",
    };
    try {
      let res = await axios.post("https://crew.ptabcd.xyz/api/message", post);

      if (res.status === 200) {
        MySwal.fire({
          html: `
          <div><img src=${checklist} style="width: 120px;"></img></div>
          <div class="mt-3 text">
            <b>Berhasil Terkirim</b>
          </div>`,
          confirmButtonText: "Tutup",
          background: "#0E2C4E",
          color: "#fff",
          padding: "2em",
          confirmButtonColor: "#26B2C3",
          customClass: {
            confirmButton: "mt-4 button-alert",
          },
        });
        setName("");
        setAddress("");
        setEmail("");
        setTelp("");
        setWebsite("");
        setMessage("");
      } else {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };
  return (
    <section className="contact" id="section-contact">
      {/* <img src={background1} className="bg-img-1"></img>
      <img src={background2} className="bg-img-2"></img> */}
      {/* <img src={bg} className="bg"></img> */}
      <img src={bgatas} className="bg"></img>
      <img src={bgbawah} className="bg"></img>
      <div className="konten">
        <div className="container">
          <div className="title">CONTACT</div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-12 col-sm-12 px-4 order-2 order-lg-1">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">
                    Nama <span className="required">*</span>{" "}
                  </label>
                  <input
                    value={nama}
                    name={nama}
                    required
                    type="text"
                    className="form-control"
                    placeholder="Masukkan nama"
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Alamat <span className="required">*</span>{" "}
                  </label>
                  <input
                    value={address}
                    required
                    type="text"
                    className="form-control"
                    placeholder="Masukkan alamat anda"
                    onChange={(e) => setAddress(e.target.value)}
                  ></input>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        Email <span className="required">*</span>{" "}
                      </label>
                      <input
                        required
                        value={email}
                        type="email"
                        className="form-control"
                        placeholder="Masukkan email"
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        No. Telepon <span className="required">*</span>{" "}
                      </label>
                      <input
                        required
                        value={telp}
                        type="text"
                        className="form-control"
                        placeholder="No telepon"
                        onChange={(e) => setTelp(e.target.value)}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Website</label>
                  <input
                    value={website}
                    type="text"
                    className="form-control"
                    placeholder="Alamat website"
                    onChange={(e) => setWebsite(e.target.value)}
                  ></input>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Pesan <span className="required">*</span>{" "}
                  </label>
                  <textarea
                    required
                    value={message}
                    type="text"
                    className="form-control"
                    placeholder="Tulis pesan"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <button className="btn btn-primary" type="submit">
                  Send Message
                </button>
              </form>
            </div>
            <div className="col-lg-5 col-md-12 px-4 order-1 order-lg-2">
              <div className="item-alamat">
                <div className="lable">
                  Sembilan Communication/ PT. JAWARA KREASITAMA
                </div>
                <div className="alamat">{sk}</div>
              </div>
              {compareData()}

              <div className="item-alamat">
                <div className="lable">FAST RESPONSE</div>
                {contact.map((item, index) => (
                  <div key={`item-${index}`} className="alamat">
                    {item.nama} : {item.telp}
                  </div>
                ))}
              </div>
              <div className="item-alamat">
                <div className="lable">MEDIA SOSIAL</div>
                <div className="d-flex list-sosmed">
                  <a
                    className="item-somed me-3"
                    href={urlHttps(sosmed.instagram)}
                    target="blank"
                  >
                    {" "}
                    <img src={instagram} className=""></img>
                  </a>
                  <a
                    className="item-somed me-3"
                    href={urlHttps(sosmed.email)}
                    target="blank"
                  >
                    {" "}
                    <img src={mail} className=""></img>
                  </a>
                  <a
                    className="item-somed me-3"
                    href={urlHttps(sosmed.twitter)}
                    target="blank"
                  >
                    {" "}
                    <img src={twitter} className=""></img>
                  </a>
                  <a
                    className="item-somed me-3"
                    href={urlHttps(sosmed.facebook)}
                    target="blank"
                  >
                    {" "}
                    <img src={facebook} className=""></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
