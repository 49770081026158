import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import logo from "../../assets/images/logo.png";
import imgbottom from "../../assets/images/post/img-bottom.svg";
import imgtop from "../../assets/images/post/img-top.svg";
import "../../assets/scss/home/home.css";
import bgDefault from "../../assets/images/news/bg-default-news.png";
import { useParams } from "react-router-dom";
import { apiUrl, dataUrl } from "../../actions/index";

import Nav from "../../parts/Nav";

export default function DetailNews() {
  const params = useParams();
  const [listData, setData] = useState([]);
  function formatDate(newdate) {
    const date = new Date(newdate);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }
  const fetchData = () => {
    fetch(`${apiUrl.detailNews(params.id)}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data.post_detail);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 2000);
  }, []);
  // const listGambar = this.props.data.portofolio_gambar;
  // const showData = () => {
  //   return (
  //     <>
  //       <div className="date">
  //         {`${this.props.data.portofolio_detail.agency} - ${this.props.data.portofolio_detail.brand}  / `}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal)} -{" "}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal_selesai)}
  //       </div>
  //       <div className="title">{this.props.data.portofolio_detail.judul}</div>

  //       <div
  //         className="desc"
  //         dangerouslySetInnerHTML={{
  //           __html: this.props.data.portofolio_detail.keterangan,
  //         }}></div>
  //     </>
  //   );
  // };
  // const showSkeleton = () => {
  //   return (
  //     <>
  //       <SkeletonTheme color="grey" highlightColor="#bfbfbf">
  //         <div className="title">
  //           {" "}
  //           <Skeleton height={20} width={300} />
  //         </div>

  //         <div className="desc">
  //           {" "}
  //           <Skeleton height={160} />
  //         </div>
  //       </SkeletonTheme>
  //     </>
  //   );
  // };

  return (
    <>
      <div className="post-page news">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              <div className="title-section">News</div>
              <div className="row mt-4">
                <div className="col-md-4">
                  <img
                    src={
                      `${listData.thumbnail == null}`
                        ? bgDefault 
                        :  `${dataUrl.news()}${listData.thumbnail}`
                    }
                    className="img-news"></img>
                </div>
                <div className="col-md-7 mt-4">
                  <div className="title">{listData.judul}</div>
                  <div
                    className="desc-content"
                    dangerouslySetInnerHTML={{ __html: listData.isi }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}
