import React, { useEffect, useState } from "react";
import "../assets/scss/timeline/timeline.css";
import bg from "../assets/images/timeline/bg.png";
import timelinemobile from "../assets/images/timeline/timeline.png";
import { apiUrl, dataUrl } from "../actions/index";

export default function Timeline({data}) {
  const [listdata, setData] = useState([]);
  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // const strDescending = [...data.year].sort((a, b) =>
  //       //   a.years > b.years ? -1 : 1
  //       // );
  //       setData(data.year);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    // fetchData();
    setData(data);
  }, [data]);
  return (
    <section className="timeline">
      <img src={bg} className="img-asset"></img>

      <img src={`${dataUrl.year()}${listdata.year_summary_mobile}`} className="timeline-mobile"></img>

      <div className="wrapper-timeline">
        <div className="">
          {/* {years.map((item, index) => (
            <div className="item-timeline" key={`item-${index}`}>
              <div className="years">{item.years}</div>
              <div className="events">{item.events} Events</div>
              <div className="clients">{item.clients} Clients</div>
            </div>
          ))} */}
        </div>
      </div>
    </section>
  );
}
