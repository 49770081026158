import React, { useEffect, useState } from "react";
import "../assets/scss/experience/experience.css";
import { Link } from "react-router-dom";
import { apiUrl, dataUrl } from "../actions/index";


export default function Experience({data}) {
  function formatDate(newdate) {
    const date = new Date(newdate);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }
  const [experience, setExperience] = useState([]);
  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setExperience(data.portofolio);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    // fetchData();
    setExperience(data);
  }, [data]);
  return (
    <section className="experience" id="section-experience">
      <div className="container">
        <div className="title">THE EXPERIENCE</div>
        <div className="list-activity">
          <div className="row">
            {experience.slice(0, 4).map((item, index) => (
              <div
                key={`item-${index}`}
                className={`col-12 col-lg-4 item-activity`}
              >
                <Link to={`/portofolio/${item.id}`}>
                  <div className="">
                    <img
                      src={`${dataUrl.experience()}${item.thumbnail}`}
                      alt={`${dataUrl.experience()}${item.thumbnail}`}
                    ></img>
                    <div className="mt-2">
                      <div className="title-item">{item.judul}</div>
                      <div className=" d-flex align-items-center">
                        <div className="line"></div>
                        <div className="desc-item">
                          {item.nama_brand} / {formatDate(item.tanggal_mulai)} -{" "}
                          {item.tanggal_selesai
                            ? formatDate(item.tanggal_selesai)
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          {/* <div className="item-activity item-activity-1">
            <Link route="/aktivitas/detail">
              <img src={img1} alt={img1}></img>
              <div className="title-item">Bayer Annual Conference</div>
              <div className="d-flex align-items-center">
                <div className="line"></div>
                <div className="desc-item">Bayer Indonesia Natcon / 10 - 12 Februari 2020</div>
              </div>
              <div></div>
            </Link>
          </div>
          <div className="item-activity item-activity-2 item-right">
            <Link route="/aktivitas/detail">
              <div className="d-flex">
                <img src={img4} alt={img4}></img>
                <div>
                  <div className="title-item ms-3">
                  MY PERTAMINA FUNWALK 2019
                  </div>
                  <div className=" d-flex align-items-center ms-3">
                    <div className="line"></div>
                    <div className="desc-item">DPRD Kota Semarang / 6 JANUARI 2019</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="item-activity item-activity-3 item-right">
            <Link route="/aktivitas/detail">
              <div className="d-flex">
                <img src={img3} alt={img3}></img>
                <div>
                  <div className="title-item ms-3">
                  KAPAL API MATA NAJWA BOYOLALI
                  </div>
                  <div className="d-flex align-items-center ms-3">
                    <div className="line"></div>
                    <div className="desc-item">KAPAL API / 22 - 23 Februari 2019</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="item-activity item-activity-4 item-right">
            <Link route="/aktivitas/detail">
              <div className="d-flex">
                <img src={img2} alt={img4}></img>
                <div>
                  <div className="title-item ms-3">
                    LACTOGROW MINI BANG SOLO
                  </div>
                  <div className="d-flex align-items-center ms-3">
                    <div className="line"></div>
                    <div className="desc-item">KAPAL API / 4 April 2017</div>
                  </div>
                </div>
              </div>
            </Link>
          </div> */}
        </div>

        <Link to="/portofolio">
          <div className="text-center mt-5">
            <div className="btn btn-more">See More</div>
          </div>
        </Link>
      </div>
    </section>
  );
}
