import React from "react";
import "../assets/scss/footer/footer.css";

import footermobile from "../assets/images/footer/footer.png";
import bg from "../assets/images/footer/bg.png";

export default function Footer() {
  return (
    <section className="footer">
      <img src={footermobile} className="footer-mobile"></img>
      <img src={bg} className="bg"></img>
      <div className="copyright">
        <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
        <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
      </div>
    </section>
  );
}
