import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import logo from "../../assets/images/logo.png";
import imgbottom from "../../assets/images/post/img-bottom.svg";
import imgtop from "../../assets/images/post/img-top.svg";
import "../../assets/scss/home/home.css";
import bgDefault from "../../assets/images/news/bg-default-news.png";
import { apiUrl, dataUrl } from "../../actions/index";
import ReactPaginate from "react-paginate";

import Nav from "../../parts/Nav";

export default function MoreNews() {
  const [news, setNews] = useState([]);
  const [listPage, setPage] = useState([]);
  const [filter, setFilter] = useState({
    currentPage: 1,
    keyword : ""
  });
  const fetchData = () => {
    fetch(`${apiUrl.news()}&page=1`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNews(data.list.data);
        setPage(data.list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    });
  }, []);

  const filterData = async (props) => {
    fetch(`${apiUrl.news()}&page=${props.currentPage}&filter_keyword=${props.keyword}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNews(data.list.data);
        setPage(data.list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    filter.currentPage = currentPage;
    setFilter(filter);
    filterData(filter);
  };
  const handleKeyword = (event) => {
    const setKeyword = event.target.value;
    filter.keyword = setKeyword;
    setFilter(filter);
    filterData(filter);
  };

  // const listGambar = this.props.data.portofolio_gambar;
  // const showData = () => {
  //   return (
  //     <>
  //       <div className="date">
  //         {`${this.props.data.portofolio_detail.agency} - ${this.props.data.portofolio_detail.brand}  / `}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal)} -{" "}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal_selesai)}
  //       </div>
  //       <div className="title">{this.props.data.portofolio_detail.judul}</div>

  //       <div
  //         className="desc"
  //         dangerouslySetInnerHTML={{
  //           __html: this.props.data.portofolio_detail.keterangan,
  //         }}></div>
  //     </>
  //   );
  // };
  // const showSkeleton = () => {
  //   return (
  //     <>
  //       <SkeletonTheme color="grey" highlightColor="#bfbfbf">
  //         <div className="title">
  //           {" "}
  //           <Skeleton height={20} width={300} />
  //         </div>

  //         <div className="desc">
  //           {" "}
  //           <Skeleton height={160} />
  //         </div>
  //       </SkeletonTheme>
  //     </>
  //   );
  // };

  return (
    <>
      <div className="post-page news">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="title-section w-100">News</div>
                <input
                  type="text"
                  className="form-control search-input"
                  id="exampleFormControlInput1"
                  placeholder="kata kunci"
                  onChange={handleKeyword}
                  ></input>
              </div>
              <div className="list-item">
                <div className="row">
                  {news.map((item, index) => (
                    <div className="col-md-4 col-12 mb-4" key={`item-${index}`}>
                      <Link className="item" to={`/news/${item.id}`}>
                        <div className="wrapper-item">
                          <div className="overlay"></div>
                          <img
                            src={
                              `${item.thumbnail == null}`
                                ? bgDefault
                                : `${dataUrl.news()}${item.thumbnail}`
                            }></img>
                          <div className="title-item">{item.judul}</div>
                        </div>
                      </Link>
                    </div>
                  ))}

                  {/* <div className="col-md-4 col-12 mb-4">
                    <Link className="item">
                      <div className="wrapper-item">
                        <div className="overlay"></div>
                        <img src={bgDefault}></img>
                        <div className="title-item">
                          Lowongan Junior Staff Lini Bisnis
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-12 mb-4">
                    <Link className="item">
                      <div className="wrapper-item">
                        <div className="overlay"></div>
                        <img src={bgDefault}></img>
                        <div className="title-item">
                          Lowongan Junior Staff Lini Bisnis
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-12 mb-4">
                    <Link className="item">
                      <div className="wrapper-item">
                        <div className="overlay"></div>
                        <img src={bgDefault}></img>
                        <div className="title-item">
                          Lowongan Junior Staff Lini Bisnis
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-12 mb-4">
                    <Link className="item">
                      <div className="wrapper-item">
                        <div className="overlay"></div>
                        <img src={bgDefault}></img>
                        <div className="title-item">
                          Lowongan Junior Staff Lini Bisnis
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-12 mb-4">
                    <Link className="item">
                      <div className="wrapper-item">
                        <div className="overlay"></div>
                        <img src={bgDefault}></img>
                        <div className="title-item">
                          Lowongan Junior Staff Lini Bisnis
                        </div>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={listPage.last_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}
